<template>
  <div class="wbobyy">
    <div id="wrapper">
        <div class="input-data">
            <input type="text" v-model="Noitem" @keyup.enter="UserDataSelect(Noitem)" required>
            <div class="underline">
              <i class="el-icon-circle-close" v-show="Noitem.length > 0" style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="Noitem = ''"></i>
              <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search" @click="UserDataSelect(Noitem)"></el-button>
            </div>
            <label>{{$t('All.请输入工单号')}}</label>
        </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: '',
      Numbers: ''
    }
  },
  created () {
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename'],
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData']),
    async UserDataSelect (row) {
      const postdata = {
        Name: row,
        Factory: this.$store.state.Login.Factory
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/FQC/NewFQCuserjyy', postdata)
      if (res.status !== 200) {
        loadingInstance.close()
        this.UserData(null)
        return this.$message.error(res.msg)
      }
      this.QcCount(res.response)
      loadingInstance.close()
    },
    async QcCount (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const QcCountLogin = {
          Login: this.$store.state.Login,
          Type: 'FQC'
        }
        const { data: res } = await this.$http.post('/api/User/QcCount', QcCountLogin)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        if (row.Fqctestlist.length === 0) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.模版实验项目未维护料号') + row.Item_no)
        }
        this.Numbers = res.response
        this.$message.success(res.msg)
        row.Region = this.$store.state.Login.Factory
        row.Numbers = this.Numbers
        row.Fqctestlist.forEach(element => {
          element.Number = this.Numbers
        })
        row.Parcss.forEach(element => {
          element.Numbers = this.Numbers
        })
        this.UserData(row)
        loadingInstance.close()
        this.Addtab(this.$t('All.新FQC检验单') + '-' + this.Numbers, 'NewFQCUser')
        this.RemoveTab(this.$t('All.新FQC检验单'))
      } catch (error) {
        loadingInstance.close()
        alert(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobyy{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 0px;
  width: 300px;
}
</style>
